<template>
  <div class="page-custom page-independent container-fluid pt-4 pb-5">
    <div class="container mt-2">
      <div class="row">
        <div class="col-xl-1"></div>
        <div class="col-xl-10 order-xl-1" v-if="loaded">
          <card shadow type="secondary">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t.translate("TTL_USER_INFORMATION") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <div class="pl-lg-4 pr-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_BUSINESS_TEAM_NAME")
                    }}</label>
                    <base-input
                      v-model="formData.Name"
                      :valid="!$h.isBlank(formData.Name)"
                    />
                  </div>

                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_ASSIGNED_BUSIN_STAFFS")
                    }}</label>
                    <div>
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        @change="modifyStaff"
                        v-model="formData.StaffList"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="searchStaff"
                        class="form-group w-100"
                        :class="{ 'inp-invalid': $h.isBlank(formData.StaffList) }"
                      >
                        <el-option
                          v-for="item in staffsOptions"
                          :key="item.UserKey"
                          :label="$h.getFullName(item)"
                          :value="item.UserKey"
                        />
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_BUSINESS_TEAM_HEAD")
                    }}</label>
                    <div>
                      <el-select
                        :disabled="!formData.StaffList.length"
                        v-model="formData.TeamHead"
                        :placeholder="$t.translate('FRM_SELECT')"
                        class="form-group w-100"
                        clearable
                        :class="{ 'inp-invalid': $h.isBlank(formData.TeamHead) }"
                      >
                        <el-option
                          v-for="item in headStaffsOptions"
                          :key="item.UserKey"
                          :label="$h.getFullName(item)"
                          :value="item.UserKey"
                        />
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-bottom">
    <div></div>
    <div class="d-flex">
      <el-button type="default" class="mr-4 d-none d-md-block" @click="$r.goBack">
        {{ $t.translate("BTN_CANCEL") }}
      </el-button>
      <el-button type="primary" class="" @click="saveChanges">
        {{ $t.translate("BTN_CONFIRM") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import _ from "lodash";
import { onMounted, reactive, ref, computed } from "vue";
import { helpers } from "@/utils/helpers";
import { get, post, isValidResponse } from "@/utils/axios";
import { router } from "@/utils/router";
import { filter } from "minimatch";

export default {
  setup() {
    const loaded = ref(false);
    const route = useRoute();

    let formData = reactive({
      BusinessTeamKey: route?.params?.teamkey || null,
      Name: "",
      TeamHead: null,
      StaffList: [],
    });

    let defaultStaffList = ref([]);
    let headStaffsOptions = ref([]);
    let staffsOptions = ref([]);

    onMounted(async () => {
      let res = await get("/user/list", { RoleCode: "BUSINESS", page: 1, size: 9999 });
      let filterList = res?.data || [];

      if (route?.params?.teamkey) {
        let resp = await get("/business/team/list", {
          page: 1,
          size: 1,
          BusinessTeamKey: route.params.teamkey,
        });
        let BusinessTeam = resp?.data?.[0] || null;
        formData.Name = BusinessTeam.Name;
        formData.TeamHead = BusinessTeam?.TeamHead?.UserKey || null;
        formData.StaffList = _.map(BusinessTeam.StaffList, (r) => r.UserKey);

        if (BusinessTeam.StaffList.length) {
          filterList = filterList.concat(BusinessTeam.StaffList);
          filterList = _.uniqBy(filterList, function (e) {
            return e.UserKey;
          });
          //from staff list generate headoptions
          headStaffsOptions.value = BusinessTeam.StaffList;
        }
      }

      staffsOptions.value = filterList;

      loaded.value = true;
    });

    const searchHead = async (query) => {
      // if (query) {
      //   let res = await get("/user/list", {
      //     page: 1,
      //     size: 999,
      //     RoleCode: "BUSINESS",
      //     Keyword: query,
      //   });
      //   headStaffsOptions.value = res?.data || [];
      // } else {
      //   headStaffsOptions.value = [];
      // }
    };

    const searchStaff = async (query) => {
      if (query) {
        let res = await get("/user/list", {
          page: 1,
          size: 999,
          RoleCode: "BUSINESS",
          Keyword: query,
        });
        staffsOptions.value = res?.data || [];
      } else {
        staffsOptions.value = [];
      }
    };

    const saveChanges = async () => {
      if (helpers.isSubmitting()) return;
      let res = await post("/business/team/submit", formData);
      if (!isValidResponse(res)) return;

      helpers.showMessage({
        Code: "MSG_TEAM_UPDATED",
        Message: "Team successfully updated!",
      });
      router.goBack();
    };

    const modifyStaff = async (ret) => {
      if (!ret.length) {
        formData.TeamHead = null;
      }

      if (!(ret.indexOf(formData.TeamHead) > -1)) {
        formData.TeamHead = null;
      }

      //get userlist based on userkey
      let res = await get("/user/list", {
        page: 1,
        size: ret.length,
        RoleCode: "BUSINESS",
        UserKeys: ret.join(","),
      });
      headStaffsOptions.value = res?.data || [];
      //alert("modify now");
    };

    return {
      modifyStaff,
      loaded,
      formData,
      defaultStaffList,
      headStaffsOptions,
      staffsOptions,
      searchHead,
      searchStaff,
      saveChanges,
    };
  },
};
</script>
<style></style>
